import React from "react";
import { Link } from "react-router-dom";
import ScrollTop from "../ScrollTop/ScrollTop";
import {useDispatch,useSelector} from 'react-redux'
import { ReactComponent as SilentAuctionIcon } from '../../../images/SA_icon.svg';

// import Auth_Actions from '../../../redux/auth/actions'
// import {useDispatch,useSelector} from 'react-redux'
// import logo from "../../../public/assets/images/logo.png"
import "./footer.css";
function Footer(){

  const Auth = useSelector((state) => state.Auth);
  // const dispatch = useDispatch()
  // const Auth = useSelector((state) => state.Auth);
  
    return (
      <footer className="novi-bg novi-bg-img footer-modern">
        <ScrollTop />
        <div className="container">
          <div className="footer-modern__layer footer-modern__layer_top">
            <a className="brand" href="https://elevateauctions.com/">
              <img
                className="brand-logo-dark"
                src={require("../../../images/logo.png")}
                alt=""
              />
            </a>
            <ul className="list-nav">
              <li>
                <Link to="/">Home</Link>
              </li>
              {Auth.auctionData?.HasSilentAuction &&
                <li>
                  <Link to={"/silent-auction/" + Auth.auctionData?.ShortCode}>Silent Auction</Link>
                </li>
              }
              {Auth.auctionData?.HasLiveAuction &&
                <li>
                  <Link to={"/live-auction/" + Auth.auctionData?.ShortCode}>Live Auction</Link>
                </li>
              }
              {Auth.auctionData?.HasDonation &&
                <li>
                  <Link to={"/donate/" + Auth.auctionData?.ShortCode}>{Auth.auctionData && Auth.auctionData.DonateButtonText ? Auth.auctionData.DonateButtonText : 'Donate'}</Link>
                </li>
              }

{Auth.auctionData?.HasAbout &&
              <li>
                <Link to={"/about/" + Auth.auctionData?.ShortCode}>About</Link>
              </li>
}
              <li>
                <Link to="/my-account">Account</Link>
              </li>
              <li>
                <Link to="/checkout">Checkout</Link>
              </li>
              <li>
                <Link to={"/faq/" + Auth.auctionData?.ShortCode}>FAQ</Link>
              </li>
            </ul>
            <ul className="list-inline-xxs footer-modern__list">
              {Auth.auctionData?.FacebookURL &&
              <li> 
              <a aria-hidden="true" target="_blank"
                className="icon novi-icon icon-xxs icon-primary fa fa-facebook"
                href={"https://www.facebook.com/"+Auth.auctionData?.FacebookURL}>&nbsp;</a>
            </li>
              }
              {Auth.auctionData?.TwitterURL &&
              <li>
                <a aria-hidden="true" target="_blank"
                  className="icon novi-icon icon-xxs icon-primary fa fa-twitter"
                  href={"https://www.twitter.com/"+Auth.auctionData?.TwitterURL}>&nbsp;</a>
              </li>
              }
              {Auth.auctionData?.InstagramURL &&
              <li>
                <a aria-hidden="true" target="_blank"
                  className="icon novi-icon icon-xxs icon-primary fa fa-instagram"
                  href={"https://www.instagram.com/"+Auth.auctionData?.InstagramURL}>&nbsp;</a>
              </li>
              } 
              {Auth.auctionData?.WebsiteURL &&
              <li>
                <a aria-hidden="true" target="_blank" className="icon novi-icon icon-xxs icon-primary fa fa-globe" href={Auth.auctionData?.WebsiteURL}>&nbsp;</a>
              </li>
              }
            </ul> 
          </div>
          <div className="footer-modern__layer footer-modern__layer_bottom">
            <p className="rights">
              <span>Made with love in Canada</span>.
            </p>
            <ul className="list-bordered">
              <li>
                <dl className="list-terms-minimal">
                  <dt>Event Contact</dt>
                  <dd>
                    <a href={"mailto:"+Auth.auctionData?.ContactEmail} alt="charityemail@event.com">
                      {Auth.auctionData?.ContactEmail}
                    </a>
                  </dd>
                </dl>
              </li>
            </ul>
          </div>
        </div>

        {/* Footer responisve */}
        <footer className="footer">
          <div
            id="buttonGroup"
            className="btn-group selectors"
            role="group"
            aria-label="Basic example"
          >
            <button
              id="home"
              type="button"
              className="btn btn-secondary button-inactive"
            >
              <Link to={"/silent-auction/" + Auth.auctionData?.ShortCode}>
                <div className="selector-holder silent-auction-icon">
                <SilentAuctionIcon className="icon icon-md link-gray-4"/>
                  <span>Auction</span>
                </div>
              </Link>
            </button>
            <button
              id="feed"
              type="button"
              className="btn btn-secondary button-inactive"
            >


              <Link to= {Auth.auth ? "/cart/" + Auth.auctionData.ShortCode : "/signin?returnUrl=" + "/cart/" + Auth.auctionData?.ShortCode}>
                <div className="selector-holder">
                  <i className="icon icon-md fa-shopping-cart link-gray-4"></i>
                  <span>Checkout</span>
                </div>
              </Link>
            </button>
            <button
              id="create"
              type="button"
              className="btn btn-secondary button-inactive"
            >
              <Link to={ Auth.auth ? "/my-account/" + Auth.auctionData?.ShortCode : "/signin?returnUrl=" + "/my-account/" + Auth.auctionData?.ShortCode}>
                <div className="selector-holder">
                  <i className="icon icon-md fa-user link-gray-4"></i>
                  <span>Account</span>
                </div>
              </Link>
            </button>
            <button
              id="account"
              type="button"
              className="btn btn-secondary button-inactive"
            >
              <Link to={Auth.auth ? "/my-bids/" + Auth.auctionData?.ShortCode : "/signin?returnUrl=" + "/my-bids/" + Auth.auctionData?.ShortCode}>
                <div className="selector-holder">
                  <i className="icon icon-md fa-gavel link-gray-4"></i>
                  <span>My Bids</span>
                </div> 
              </Link>
            </button>
          </div>
        </footer>
      </footer>
    );
  }


export default Footer;
