import React,{useEffect} from "react";
import { Link, NavLink,useParams } from "react-router-dom";
import CountDownTimer from "../CountDown/CountDownTimer";
import "./header.css";
import Auth_Actions from '../../../redux/auth/actions'
import {useDispatch,useSelector} from 'react-redux'
function Header(){ 
  const {shortcode} = useParams();
  const dispatch = useDispatch()
  //const param = useParams();
  const Auth = useSelector((state) => state.Auth);
  const signOut = () => dispatch(Auth_Actions.signOut())


 useEffect(() => {
  //console.log(shortcode);
  if (shortcode) {
    dispatch(Auth_Actions.auction({ShortCode: shortcode,afterAction: () => {
      //document.documentElement.style.setProperty('--accentColor', Auth.auctionData.AccentColor);
      if (Auth.auctionData) {
        
        document.documentElement.style.setProperty('--accentColor', Auth.auctionData.AccentColor);    
      }
    
     
      }
    }));
   
  }
  
  if (Auth.auctionData) {        
    document.documentElement.style.setProperty('--accentColor', Auth.auctionData.AccentColor);    
  }
  
  }, [dispatch,shortcode,Auth?.auctionData?.AccentColor]);

var handleClosingMenu = function (event) {
 // console.log('test');
  const box = document.getElementById('navbarTogglerDemo01');
  const button = document.querySelector('.navbar-toggler');
  const header = document.querySelector('.page-header');
 
  if(box)
  {
    box.classList.remove('show');
    if(!button.contains(event.target)){
      document.querySelector('.navbar-toggler').classList.remove('active');
    }
  }
  
}

  const navLinks = document.querySelectorAll('.nav-link') 
  navLinks.forEach((l) => {
      l.addEventListener('click', handleClosingMenu)
  })

  document.addEventListener('click', handleClosingMenu);

    return (
      
        <header className="page-header">
          
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="icon-bar top-bar"></span>
            <span className="icon-bar middle-bar"></span>
            <span className="icon-bar bottom-bar"></span>
          </button>

          {Auth.auctionData?.DisplayCountdownOnTablet && 
            <div className="timer">
              <CountDownTimer Time={Auth.auctionData?.EndDateTime} />
            </div>
          }

          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
         
            <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
              <li className="nav-item px-lg-2">
                <NavLink exact  className="nav-link" activeClassName="active" 
                to={"/" + Auth.auctionData?.ShortCode} >
                  Home
                </NavLink>
              </li>
              {Auth.auctionData?.HasSilentAuction &&
              <li className="nav-item px-xl-2">              
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to={"/silent-auction/" + Auth.auctionData.ShortCode}
                >
                  Silent Auction
                </NavLink>
                </li>
              }              
              
              {Auth.auctionData?.HasLiveAuction &&
              <li className="nav-item px-xl-2">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  
                  to={"/live-auction/" + Auth.auctionData.ShortCode}
                >
                  Live Auction
                </NavLink>
              </li>
}
              {Auth.auctionData?.HasDonation &&
                <li className="nav-item px-xl-2">
                  <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to={"/donate/" + Auth.auctionData.ShortCode}
                  >{Auth.auctionData && Auth.auctionData.DonateButtonText ? Auth.auctionData.DonateButtonText : 'Donate'}</NavLink>
              </li>
              }

              {Auth.auctionData?.HasAbout &&
              <li className="nav-item px-xl-2">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to={"/about/" + Auth.auctionData?.ShortCode}
                >
                  About
                </NavLink>
              </li>
              }
              <li className="nav-item px-xl-2">
                <NavLink
                  className="nav-link"
                  activeClassName="active"
                  to={"/faq/" + Auth.auctionData?.ShortCode} 
                >
                  Faq
                </NavLink>
              </li>

              {Auth.auctionData?.RaffleLinkText && Auth.auctionData?.RaffleLinkURL &&
                <li className="nav-item px-xl-2">
                  <a 
                    target="_blank" 
                    href={Auth.auctionData.RaffleLinkURL} 
                    className="nav-link">    {Auth.auctionData.RaffleLinkText}                
                  </a>                  
              </li>
              }
            </ul>
          </div>

          <div className="rd-navbar-nav-wrap__element">
          
            <Link className="icon novi-icon icon-md linear-icon-hammer2  link-gray-4" to={Auth.auth ? "/my-bids/" + Auth.auctionData?.ShortCode : "/signin?returnUrl=" + "/my-bids/" + Auth.auctionData?.ShortCode}
            ></Link>
            <Link className="icon novi-icon icon-md linear-icon-cart link-gray-4" to= {Auth.auth ? "/cart/" + Auth.auctionData.ShortCode : "/signin?returnUrl=" + "/cart/" + Auth.auctionData?.ShortCode}></Link>
            <Link className="icon novi-icon icon-md linear-icon-user link-gray-4" to={ Auth.auth ? "/my-account/" + Auth.auctionData?.ShortCode : "/signin?returnUrl=" + "/my-account/" + Auth.auctionData?.ShortCode}></Link>
            {Auth?.auth?.access_token?
           <button className="button button-primary text-capitalize rounded-0" onClick={()=>signOut()} >Sign Out</button> :
           <Link className="button button-primary text-capitalize rounded-0" to={"/signin?returnUrl=" + window.location.pathname}>Sign In</Link>
          } </div>
          </div>
        </nav>
        </header>
    );
  }


export default Header;
